export enum CandidateType {
  APPLICATION = 'APPLICATION',
  COLLECTION = 'COLLECTION',
}

export enum ApprovalState {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export interface ManagedCategoryCandidate {
  id?: number;
  candidateId?: number;
  candidateType?: CandidateType;
  categoryName: string;
  categoryId?: string;
  permission?: string;
  approvalState?: ApprovalState;
  approvalInfoUrl?: string;
}
