import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, take, throwError } from 'rxjs';
import { ManagedCategory } from '../../interfaces/managed-category-interfaces/managed-category.interface';
import {
  ApprovalState,
  CandidateType,
  ManagedCategoryCandidate,
} from '../../interfaces/managed-category-interfaces/managed-category-candidate.interface';
import { environment } from '../../../../environments/environment';
import { PermissionType } from 'src/app/pages/app-integrator-page/enums/permission-type.enum';
import { UserService } from '../user-service/user.service';
import { PermissionsService } from 'src/app/pages/app-integrator-page/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ManagedCategoriesApiService {
  private baseURL = environment.baseUrl + '/categories';

  constructor(private http: HttpClient,
    private userService: UserService,
    private permissionsService: PermissionsService
  ) {
  }

  public getManagedCategories(): Observable<ManagedCategory[]> {
    return this.http.get<ManagedCategory[]>(this.baseURL);
  }

  public getCategoryCandidates(): Observable<ManagedCategoryCandidate[]> {
    return this.http.get<ManagedCategoryCandidate[]>(
      this.baseURL + '/candidates',
    );
  }

  public getCategoryCandidateApprovalState(
    _candidateId: number,
    _candidateType: CandidateType,
  ): Observable<ManagedCategoryCandidate> {
    return this.http.get<ManagedCategoryCandidate>(
      this.baseURL + `/candidates/${_candidateId}/type/${_candidateType}`,
    );
  }

  public requestCategoryApproval(
    _categoryId: string,
    _candidateId: number,
    _candidateType: CandidateType,
  ): Observable<void> {
    return this.http.post<void>(this.baseURL + `/candidates`, {
      candidateId: _candidateId,
      candidateType: _candidateType,
      categoryId: _categoryId,
    });
  }

  public revokeCategoryApproval(_id: number): Observable<void> {
    return this.http.delete<void>(this.baseURL + `/candidates/${_id}`);
  }

  public revokeCategoryApprovalForCandidate(
    _candidateId: number,
    _candidateType: CandidateType,
  ): Observable<void> {
    return this.getCategoryCandidateApprovalState(_candidateId, _candidateType)
      .pipe(
        take(1),
        catchError((error) => {
          if (error.status === 404) {
            return of(void 0); // Continue with an empty Observable if 404 (No Candidate)
          } else {
            return throwError(() => error); // Any other error
          }
        }),
        switchMap((candidate) =>
          candidate ? this.revokeCategoryApproval(candidate.id!).pipe(take(1)) : of(void 0)
        ),
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  public updateCategoryCandidateApprovalState(
    _id: number,
    _approvalState: ApprovalState,
  ): Observable<void> {
    return this.http.put<void>(this.baseURL + `/candidates/${_id}`, {
      approvalState: _approvalState,
    });
  }

  public checkIfUserCanManageApprovalState(
    _categoryPermission: PermissionType,
  ) {
    const user = this.userService.getUser();
    return (
      this.permissionsService.checkPermission(
        user,
        PermissionType.UseAdminUI,
      ) || this.permissionsService.checkPermission(user, _categoryPermission)
    );
  }
}
