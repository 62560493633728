import {catchError, map, Observable, of} from "rxjs";
import {
  CandidateType,
  ManagedCategoryCandidate
} from "../../../interfaces/managed-category-interfaces/managed-category-candidate.interface";
import {ManagedCategory} from "../../../interfaces/managed-category-interfaces/managed-category.interface";
import {
  ManagedCategoriesApiService
} from "../../../services/managed-categories-api-service/managed-categories-api.service";
import {FormControl} from "@angular/forms";
import {Injectable, signal, Signal, WritableSignal} from "@angular/core";
import {CATEGORY_NONE} from "../../../constants/categories.constant";


type NullableManagedCategoryCandidate = ManagedCategoryCandidate | null | undefined;

@Injectable({
  providedIn: 'root',
})
export abstract class CategoryDataService {

  public candidateType: CandidateType = CandidateType.APPLICATION;
  private managedCategories: WritableSignal<ManagedCategory[]> = signal([]);

  protected constructor(private managedCategoryApiService: ManagedCategoriesApiService) {
    this.loadManagedCategories();
  }

  private _categoryIDFromDB: string = CATEGORY_NONE.value;

  get categoryIDFromDB(): string {
    return this._categoryIDFromDB;
  }

  set categoryIDFromDB(value: string) {
    this._categoryIDFromDB = value;
  }

  private _managedCategoryCandidate: NullableManagedCategoryCandidate;

  get managedCategoryCandidate(): NullableManagedCategoryCandidate {
    return this._managedCategoryCandidate;
  }

  set managedCategoryCandidate(
    value: ManagedCategoryCandidate | null | undefined,
  ) {
    this._managedCategoryCandidate = value;
  }

  loadManagedCategories(): void {
    this.managedCategoryApiService
      .getManagedCategories()
      .subscribe((categories) => {
        this.managedCategories.set(categories);
      });
  }

  getCategories(): Signal<ManagedCategory[]> {
    return this.managedCategories;
  }

  findCategoryByValue(value: string): ManagedCategory | undefined {
    return this.managedCategories()
      .find((category) => category.id === value);
  }

  getCategoryCandidateApprovalState(
    appId: number,
  ): Observable<ManagedCategoryCandidate | null> {
    return this.managedCategoryApiService
      .getCategoryCandidateApprovalState(appId, this.candidateType)
      .pipe(
        map((managedCategoryCandidate) => {
          return managedCategoryCandidate;
        }),
      );
  }

  updateCategoryApprovalCandidate(appId: number, categoryFormControl: FormControl) {
    this
      .getCategoryCandidateApprovalState(appId)
      .subscribe(managedCategoryCandidate => {
        this._managedCategoryCandidate = managedCategoryCandidate;
        let category: ManagedCategory | null = null;
        if (managedCategoryCandidate !== null && "categoryId" in managedCategoryCandidate) {
          category = this.findCategoryByValue(managedCategoryCandidate.categoryId!) ?? null;
        }
        // Set temporary Managed Category value for dropdown
        categoryFormControl.setValue(category, {emitEvent: false});
      })
  }

  resetCategoryApprovalCandidate() {
    this._managedCategoryCandidate = undefined;
  }

  mapManagedCategoryFormFieldValue(_candidateId: number | undefined, _categoryValue: string | undefined, _control: FormControl<ManagedCategory | null>) {
    // Handle Managed Categories & Regular Categories
    if (_candidateId) {
      // Edit Mode
      this.managedCategoryApiService.getCategoryCandidateApprovalState(_candidateId, this.candidateType)
        .pipe(
          catchError(error => {
            if (error.status === 404) { // 404
              // Regular Categories & already approved Categories. They don't have managed category candidates.
              // 404-Backend-Handling is not good imo.
              _control.setValue(this.findCategoryByValue(_categoryValue ?? CATEGORY_NONE.value) ?? null);
              this.categoryIDFromDB = _categoryValue as string;
              return of(null);
            } else {
              throw error;
            }
          }),
        )
        .subscribe((managedCategoryCandidate) => {
          if (managedCategoryCandidate) {
            // Categories with Approval Request. They have managed category candidates.
            // Set the managed category candidate in CategoryDataService
            this.managedCategoryCandidate = managedCategoryCandidate;
            _control.setValue(this.findCategoryByValue(_categoryValue ?? CATEGORY_NONE.value) ?? null);
            this.updateCategoryApprovalCandidate(_candidateId, _control);
          }
        });
    } else {
      // Create Mode
      _control.setValue(this.findCategoryByValue(CATEGORY_NONE.value) ?? null);
      this.categoryIDFromDB = 'None';
    }
  }

  requestCategoryApproval(_categoryId: string, _candidateId: number) {
    return this.managedCategoryApiService.requestCategoryApproval(_categoryId, _candidateId, this.candidateType);
  }

  revokeCategoryApprovalForCandidate(_candidateId: number) {
    return this.managedCategoryApiService.revokeCategoryApprovalForCandidate(_candidateId, this.candidateType);
  }
}
